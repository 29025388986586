<template>
  <div class="header clr">
    <div class="logo fl">
      <img :src="logo" alt />
      <!-- 搭个圈后台管理系统 -->
    </div>
    <div class="header-right fr clr">
      <div class="fl">
        <Dropdown placement="bottom-end" @on-click="avatarEvents">
          <div class="user-area">
            <Avatar src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
            <span class="user-name">{{userInfo.nickName}}</span>
          </div>
          <DropdownMenu slot="list">
            <DropdownItem name="logout">退出</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo_title.png"),
      userInfo: this.$store.getters.userInfo
    };
  },
  methods: {
    avatarEvents(type) {
      switch (type) {
        case "logout":
          this.$router.push({ path: "/login" });
          break;

        default:
          break;
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #fff;
  padding: 0;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 3;
  display: block;
  .logo {
    float: left;
    text-align: left;
    width: 280px;
    height: 48px;
    color: #515a6e;
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
    // background: linear-gradient(
    //   270deg,
    //   rgba(61, 155, 251, 1) 0%,
    //   rgba(45, 125, 250, 1) 100%
    // );
    img {
      display: inline-block;
      width: 110px;
      height: 48px;
      // vertical-align: middle;
      margin: 0 0 0 20px;
    }
  }
  .header-right {
    padding-right: 20px;
    .user-area {
      padding: 0 12px;
      cursor: pointer;
    }
    .user-area:hover {
      background: #f8f8f9;
    }
  }
  .user-name {
    margin-left: 12px;
  }
}
</style>