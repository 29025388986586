<template>
  <div>
    <Header />
    <LayoutComp />
  </div>
</template>

<script>
import Header from '@/components/Header'
import LayoutComp from '@/components/Layout'
export default {
  components: {
    Header,
    LayoutComp,
  },
  data() {
    return {
    };
  },
  created() {
    console.log(123)
  },
  methods: {}
}

</script>
<style lang='scss' scoped>
</style>