<template>
  <div class="layout">
    <Layout>
      <Sider ref="side" :collapsed-width="68" v-model="isCollapsed" :width="208">
        <div class="menu-tree">
          <Menu
            :open-names="openNames"
            :active-name="activeName"
            theme="light"
            width="auto"
            accordion
          >
            <template v-for="item in navs">
              <!-- 一级菜单 有子栏目 -->
              <template v-if="item.children.length > 0">
                <Submenu :key="item.permission.id" :name="item.permission.ename">
                  <template slot="title">
                    <Icon :type="item.permission.iconName" />
                    <span v-show="!isCollapsed">{{ item.permission.cname }}</span>
                  </template>
                  <!-- 二级菜单 有子栏目 -->
                  <template v-for="(childNavChildNav, index) in item.children">
                    <template v-if="childNavChildNav.children.length > 0">
                      <Submenu
                        :key="childNavChildNav.permission.id"
                        :name="childNavChildNav.permission.ename"
                        class="childNavChildNavStyle"
                      >
                        <template slot="title">
                          <span v-show="!isCollapsed">{{ childNavChildNav.permission.cname }}</span>
                        </template>
                      </Submenu>
                    </template>
                    <!-- 二级菜单 无子栏目 -->
                    <!-- :to="{path: `/${childNavChildNav.permission.ename}`}" -->
                    <template v-else>
                      <MenuItem
                        :key="childNavChildNav.permission.id"
                        :name="childNavChildNav.permission.ename"
                        :to="{path: `/${childNavChildNav.permission.ename}`}"
                        class="childNavChildNavStyle"
                      >
                        <!-- <i :key="'circularPoint'+index" class="circularPoint" /> -->
                        <Icon
                          :type="childNavChildNav.permission.iconName"
                          :key="'circularPoint'+index"
                        />
                        <span>{{ childNavChildNav.permission.cname }}</span>
                      </MenuItem>
                    </template>
                  </template>
                </Submenu>
              </template>
              <!-- 一级菜单 无子栏目 -->
              <template v-else>
                <MenuItem
                  :key="item.permission.id"
                  :name="item.permission.ename"
                  :to="{path: `/${item.permission.ename}`}"
                >
                  <Icon :key="item.permission.id" :type="item.permission.iconName" />
                  <span>{{ item.permission.cname }}</span>
                </MenuItem>
              </template>
            </template>
          </Menu>
        </div>
      </Sider>
      <Layout>
        <Content style="padding: 12px;height:100%;">
          <Breadcrumb v-if="breads.length>0" separator=">">
            <BreadcrumbItem :to="item.path" v-for="(item, index) in breads" :key="index">{{ item }}</BreadcrumbItem>
          </Breadcrumb>
          <div class="page-view">
            <Card>
              <router-view />
            </Card>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>

export default {
  data() {
    return {
      navs: this.$store.getters.navs,
      isCollapsed: false,
      breads: this.$route.meta.bread || [],
      activeName: this.$route.path.slice(1),
      openNames: this.$route.meta.menu ? this.$route.meta.menu : [""],
    };
  },
  watch: {
    $route(to) {
      this.breads = to.meta.bread
      console.log(to)
    }
  },
  created() {
    console.log(this.$route.meta.menu)
  },
  methods: {

  }
}

</script>
<style lang='scss'>
.layout {
  padding-top: 48px;
  height: 100vh;
  .menu-tree {
    height: 100%;
    overflow: auto;
  }
  .ivu-layout {
    height: 100%;
  }
  .page-view {
    margin-top: 12px;
  }
  .ivu-menu-light.ivu-menu-vertical{
    .ivu-menu-item-active:not(.ivu-menu-submenu){
      color: #165DFF;
    }
  }
}
</style>